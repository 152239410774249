:root {
    --bg: url('./images/abstract-bg.svg');
    --button-green: #97c63a;
    --bg-grey: rgb(240, 240, 240);
    --text-header-color: #778d28;
}
body {
    /* margin-top: 20px;
    margin-bottom: 100px; */
    background: #FFFFFF;
    overflow-x: hidden;
    min-height: 100vh;
}
tr.group,
tr.group:hover {
    background-color: #ddd;
}
p, h1, h2, h3, h4, h5, h6, a, label, span, text, .log {
    font-family: arial;
    fill: #000000;
    color: #000000;
    padding-left: 10px;
    padding-right: 10px;
}
.fs-20 {
    font-size: 20px !important;
}
.fs-30 {
    font-size: 30px !important;
}
.px-1 {
    padding-left: 10px;
    padding-right: 10px;
}
.py-1 {
    padding-top: 10px;
    padding-bottom: 10px;
}
.px-2 {
    padding-left: 20px;
    padding-right: 20px;
}
.py-2 {
    padding-top: 20px;
    padding-bottom: 20px;
}
.mx-1 {
    margin-left: 10px;
    margin-right: 10px;
}
.mx-2 {
    margin-left: 20px;
    margin-right: 20px;
}
ul {
    padding: 0px;
}
.bg-green {
    background-color: var(--button-green) !important;
}
.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.inline {
    display: inline !important;
}
div {
    box-sizing: border-box;
    word-break: break-word;
}
/* img {
    width: 100%;
} */
p {
    margin: 0;
    padding-top: 1em;
    padding-bottom: 1em;
}
h1 {
    color: var(--text-header-color);
    margin: 5px 0px;
    font-size: 20px;
}
h3 {
    margin: 5px 0px;
    /* font-size: 20px; */
}
h2 {
    color: var(--text-header-color);
    /* text-shadow: 1px 1px 3px black; */
    font-size: 16px;
}
.header {
    grid-template-columns: 1fr;
    display: grid;
    place-items: center;
}
.category-header {
    font-size: 50px;
}
.container {
    max-width: 1380px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    /* margin-bottom: auto; */
}
.text-left {
    text-align: left !important;
}
.text-center {
    text-align: center !important;
}
header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
}
.bg-white {
    background-color: white;
}
.bg-offwhite {
    background-color: #f5f5f5;
}
.issue-type {
    background-color: rgba(255,255,255,.1) !important;
    margin-left: auto;
    margin-right: auto;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    border-radius: 0px;
    border-bottom: solid 1px black;
    transition-duration: .1s;
}
.issue-title, .issue-description {
    padding-left: 10px;
    padding-right: 10px;
}
.disclaimer {
    grid-column: 2 span;
}
.url-group {
    background-color: #f5f5f5;
    padding-bottom: 10px;
    display: grid;
}
.issue-type, .description, .url-group, .issue-group-text {
    width: 100%;
    padding-left: 1%;
    padding-right: 1%;
    margin-left: auto;
    margin-right: auto;
}
.issue-title {
    padding-top: 5px;
    padding-bottom: 5px;
}
.issue-title, .url {
    transition-duration: .1s;
}
.url {
    cursor: pointer;
}
.issues > div:nth-child(odd) {
    background-color: rgba(255,255,255,.1);
}
.issue-details-group > div:nth-child(odd) {
    background-color: rgba(0,0,0,.1);
}
.urls-group > .issue-type > .issue-title, .issues > div > .issue-type > .issue-title {
    cursor: pointer;
}
.url {
    padding-left: 1%;
    padding-right: 1%;
}
.url, .issue-title {
    display: grid;
    align-items: center;
}
.issue-group-text {
    transition-duration: 1s;
    overflow: hidden;
    grid-column-start: 3 span;
}
.url, .description {
    border-bottom: solid 1px white;
}
.issue-group-text > p, .issue-text {
    border-bottom: solid 1px white;
}
.issue-group-text > p:first-child, .issue-text:first-child {
    border-top: solid 1px white;
}
.issue-group-text > p:last-child, .issue-text:last-child {
    border-bottom: none;
}
.back > img {
    filter: invert(1);
}
.img-fluid {
    max-width: 100%;
}
.arrow {
    margin-left: auto;
}
#bar-chart {
    grid-column-start: 4 span;
}
.website-name {
    text-align: center;
}
.back {
    cursor: pointer;
    display: flex;
    align-items: center;
}
.back > img {
    width: 10%;
    filter: invert(1);
    margin-right: 1%;
}
.side-buttons {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    position: fixed;
}
.side-buttons button {
    font-size: 30px;
}
.progress {
    margin: auto;
}
.log {
    margin: auto;
}
.loginLabel {
    text-align: left;
    display: block;
}

.grid {
    display: grid;
}
.grid.mobile {
    grid-template-columns: 1fr !important;
}
  
.flex {
    display: flex;
}

.flex-gap {
    gap: 10px;
}

.flex img {
    align-self: start;
}

.align-self-start {
    align-self: start !important;
}

.align-self-end {
    align-self: end !important;
}

.align-self-center {
    align-self: center !important;
}

.flex-wrap {
    flex-wrap: wrap;
}
  
.column {
    flex-direction: column;
}

.error {
    padding: 10px;
    color: white;
    border-radius: 10px;
    margin: 10px;
    font-size: 20px;
    background-color: darkred;
}

.thank-you {
    padding: 10px;
    color: white;
    font-size: 30px;
    background-color: var(--text-header-color);
    border-radius: 10px;
    margin: 10px;
}

.flex-w-center {
    align-items: center;
}

.flex-h-center {
    justify-content: center;
}

.confirmationButton {
    display: flex;
    min-width: 46px;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.fullscreen {
    min-height: 100vh;
}

.loginInput {
    display: block;
    margin-bottom: 20px;
}
.fake-link {
    background-color: transparent;
    border: none;
    cursor: pointer;
    /* color: white; */
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
}

.site-matters {
    text-align: left;
}

.w-50 {
    width: 50%;
}

.w-100 {
    width: 100%;
}

.w-25 {
    width: 25%;
}

.w-75 {
    width: 75%;
}

footer {
    margin-top: auto;
}
footer p {
    color: white !important;
    text-shadow: 1px 1px 5px black;
    font-weight: 700;
    font-size: 20px;
    padding: 10px 5px;
}
footer a {
    color: white !important;
    text-decoration: none;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.homepageContainer h1 {
    font-size: 40px;
    margin-bottom: 40px;
}
.homepageContainer p {
    padding-top: 0px;
}
.homepageContainer h2 {
    margin-bottom: 5px;
    font-size: 20px;
}
.homepageContainer .callout {
    margin-top: 70px;
    color: green;
    text-shadow: 1px 1px 1px black;
    font-weight: 700;
    font-size: 27px;
}

.informationalContainer .calloutHeader {
    font-weight: 900;
    font-size: 40px;
    color: black;
}

.section {
    margin: 0px;
    padding: 40px 10px;
}

.section:nth-child(even) {
    background: var(--bg-grey);
}

.section:last-child {
    margin-bottom: 0px;
}

.section p {
    font-size: 20px;
    line-height: 2;
}
.section li {
    font-size: 20px;
}

/* Price */
.priceContainer {
    margin-bottom: auto;
}
.priceSpecList {
    padding: 20px;
    margin: auto;
    margin-bottom: 30px;
    background-color: rgba(0,0,0, 1);
    color: white;
    padding-top: 10px;
}
.priceSpecList h2 {
    color: white;
    font-size: 40px !important;
}
.priceSpecList ul {
    font-weight: 700;
    line-height: 1.5;
}
.priceSpecList li {
    /* color: white; */
    list-style: none;
    text-align: left;
    font-size: 16px;
}
.priceSpecList li span {
    margin-right: 5px;
}
.priceSpecList li .fa-check, .priceSpecList li .fa-circle {
    color: rgb(151, 198, 58) !important;
}
.priceSpecList li .fa-circle {
    font-size: 5px;
}
.listIndent {
    margin-left: 50px;
}
.priceSpecList .listIndent {
    color: white;
    display: flex;
    align-items: center;
}
.price {
    background-color: #ff6600;
    color: white;
    font-size: 30px;
    padding: 20px;
    border-radius: 50px;
    border: none;
}
.price-button .fa {
    color: white;
}
.priceContainer .price {
    color: #ff6600;
    margin: 20px;
    font-size: 24px;
    padding-top: 1em;
}
.print-logo {
    display: none;
}
.loginButtonContainer {
    margin-left: auto;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
}
.loginButtonContainer {
    /* margin: 20px auto; */
    flex-direction: column;
}

.loginButton {
    text-align: left;
    background: none;
    margin-left: auto;
    cursor: pointer;
    font-size: 20px;
    border: none;
    /* color: white; */
    font-weight: 700;
}

.synergy-bg {
    background: var(--bg);
    background-size: cover;
}

.checkingContainer {
    background-color: black;
    color: white;
}


/* Report Area */
.reportOverview, .chartGrid {
    display: grid;
    margin: auto;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
}
.reportOverview {
    padding: 0px 10px;
}

.reportHeader {
    margin-top: 20px;
    background-color: black;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.reportHeader > h1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.reportHeader span {
    color: #DDDDDD;
    fill: #DDDDDD;
}
.reportContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.reportContent {
    background-color: white;
    padding: 10px 10px 10px 10px;
    margin-bottom: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.totalIssueBox {
    background-color: darkred;
    color: white;
    font-size: 24px;
    display: flex;
    width: fit-content;
    padding: 10px;
    margin: auto;
    border-radius: 10px;
    border: 1px solid #CCCCCC;
}

.reportSiteImage {
    width: 50%;
    display: flex;
}

.reportSiteImage > img {
    margin: auto;
    border: 1px solid #CCCCCC;
}

.reportExpandedBox {
    border: 1px solid #CCCCCC;
    border-top: none;
}

.reportCard {
    /* border-left: 3px solid #CCCCCC; */
    /* border-right: 3px solid #CCCCCC; */
    cursor: pointer;
    transition-duration: 0.3s;
    border: 1px solid #CCCCCC;
}
.reportCard:hover {
    /* border-right: 3px solid #CCCCCC; */
    cursor: pointer;
    background-color:var(--button-green);
}
.reportCard:hover .header h1, .reportCard.active .issues p {
    color: black !important;
}

.reportCard.active {
    background-color:var(--button-green);
}
.reportCard.active .header h1, .reportCard.active .issues p {
    color: black !important;
}

.reportCallout {
    box-shadow: rgba(0,0,0, 1) 0px 0px 10px;
    padding: 20px;
    margin-top: 2%;
    text-align: left;
}

.reportCallout h2 {
    font-size: 24px;
    margin: 0px;
}

.reportCallout p {
    /* font-size: 20px; */
    line-height: 1.5;
}

/* Inputs */
select {
    max-width: 100%;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
}

.loginButton {
    transition-duration: 0.3s;
}

.loginButton:hover {
    color: rgb(126, 126, 255);
}

.reportContainer select {
    width: 100%;
}

#scan-controls input {
    max-width: 824px;
    width: 90%;
}

.reportContainer input {
    font-size: 32px;
}
.reportcontainer input::-webkit-input-placeholder {
    font-size: 32px;
}

#scan-controls button {
    margin-top: 10px;
    border-radius: 5px;
    font-size: 32px;
    margin-left: 5px;
    border: black 2px solid;
    transition-duration: 0.3s;
}

.confirmationButton {
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 20px;
    border: black 2px solid;
    background-color:  var(--button-green);
    transition-duration: 0.3s;
    margin: auto;
}

#scan-controls button:hover, #scan-controls button:focus, .confirmationButton:hover, .confirmationButton:focus {
    background-color: white;
    color: black;
    border: solid 1px black;
}

#run_container {
    align-items: center;
    justify-content: space-between;
}
#run_container > select {
    margin: auto;
}
#url_input {
    margin-top: 10px;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    transition-duration: 0.3s;
}
#run {
    width: 180px;
    background: black;
    color: white;
    padding: 10px;
    transition-duration: 0.3s;
}

input {
    transition-duration: 0.3s;
    max-width: 100%;
}

.reportContainer .contactContainer {
    margin: 10px 10px;
}

.contactContainer {
    align-self: stretch;
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 10px;
    box-shadow: #000000 0px 0px 10px;
}
.contactContainer h1 {
    font-size: 40px;
}
.contactInnerContainer {
    min-height: 310px;
}

.loginContainer {
    width: 400px;
    margin-top: 10%;
    padding: 50px;
    box-shadow: #000000 0px 0px 10px;
}

.passwordContainer {
    position: relative;
    margin-bottom: 20px;
}

.passwordContainer input {
    margin: 0px;
}

.togglePassword {
    cursor: pointer;
    position: absolute;
    right: 0px;
    bottom: 0px;
    background-color: white;
    margin: 2px;
    border: none;
    transition-duration: 0.3s;
}

.passwordContainer:hover input, .passwordContainer:hover .togglePassword, .passwordContainer:focus input, .passwordContainer:focus .togglePassword {
    color: black;
}

/* Header */

.headerContainer {
    background-color: white;
    box-shadow: 0px 0px 10px #000000;
    position: relative;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 20px; */
}

.headerInnerContainer {
    /* padding: 20px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

hr {
    width: 100vw;
    position: relative;
    margin-bottom: 20px;
}

.informationalContainer h1 {
    font-size: 40px;
}

.informationalContainer h2 {
    text-align: left;
    font-size: 30px;
    padding: 0px 10px;
}

.informationalContainer p {
    text-align: left;
    padding: 0px 10px;
}

.mainInfo {
    padding-bottom: 0px;
}

.congratsBox {
    /* padding: 20px; */
    margin: auto;
    margin-top: 5%;
    /* width: 90%; */
    /* box-shadow: #000000 0px 0px 10px; */
    border-radius: 10px;
    margin-bottom: 40px;
    text-align: left;
}

.congratsBox h2 {
    font-size: 24px;
}

.card-2-column > .card {
    width: 50%;
}

.url-group {
    cursor: pointer;
}

/* Loader */

.loader {
    display: flex;
}

.loader svg {
    margin: auto;
}

.loadingContainer {
    display: flex;
    flex: 1;
}
.loadingSubContainer {
    margin: auto;
}

/* Square Payment */
.sq-card-message {
    /* color: white !important; */
}
#rswp-card-button:disabled {
    position: relative;
    display: flex;
    justify-content: center;
    background-color: rgba(0,0,120,.3);
}
.paymentContainer {
    margin-top: auto;
}
.paymentContainer > .container {
    padding: 20px;
    box-shadow: #000000 0px 0px 10px;
}

#rswp-card-button:disabled:after {
    content:'Loading'; 
    /* color: white; */
    visibility: visible;
    display: block;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
}

/* Accordians */

.collapse-group {
    transition-duration: 1s;
    overflow: hidden;
    position: relative;
}
.collapse-group {
    display: none;
}
.accordian-arrow {
    position: absolute;
    top: 22px;
    right: 10px;
    content: "Arrow";
    display: block;
    /* color: white; */
}
.open {
    display: block;;
}

.logo {
    cursor: pointer;
    padding: 10px;
}

.img-bg-grey {
    background-color: var(--bg-grey);
    padding: 5px;
}

.print-score {
    display: none;
}

/* Print */

@media print {
    body {
        background: white;
    }
    .headerContainer {
        display: none;
    }
    tr.group,
    tr.group:hover {
        background-color: #ddd !important;
    }
    p, h1, h2, h3, h4, h5, h6, a, label, span, text {
        font-family: arial;
        color: black !important;
        fill: black !important;
    }
    #theme_switch, #home_button, #print_button {
        display: none;
    }
    #url, #scan-controls {
        display: none;
    }
    .chartGrid {
        display: none;
    }
    .reportCard {
        border: none !important;
    }
    .logo {
        display: none;
    }
    .print-logo {
        display: block;
    }
    .overviewContainer {
        width: 100% !important;
    }
    .reportSiteImage {
        display: none;
    }
    .reportHeader h1 span {
        color: white !important;
    }
    .print-score {
        display: block;
    }
    .issues p {
        padding: 5px 0px;
    }
    .totalIssueBox {
        color: white !important;
    }
    #overAllChart {
        display: none !important;
    }
}

@media screen and (min-width: 420px) {
    .loginButtonContainer {
        margin-left: auto;
        margin-right: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        gap: 20px;
    }
}

@media screen and (min-width: 600px) {
    .contactContainer {
        align-self: center;
        padding: 40px;
    }
    .loginButtonContainer {
        flex-direction: row;
    }
    .logo {
        padding: 0px;
    }
    /* Circle */
    .logoContainer::before {
        content: " ";
        display: block;
        width: 50px;
        margin: 15px;
        height: 50px;
        background: var(--bg);
        background-size: cover;
        border-radius: 100%;
    }
}

@media screen and (min-width: 1070px) {
    .chartGrid, .reportOverview {
        margin-top: 20px;
        padding: 0px;
        display: grid;
        grid-template-columns: repeat(auto-fill, 340px);
    }
    .grid-2-column {
        grid-template-columns: 1fr 1fr;
        display: grid;
    }
    .informationGrid {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    p, h1, h2, h3, h4, h5, h6, a, label, span, text, .log {
        padding-left: 0px;
        padding-right: 0px;
    }
    .priceSpecList {
        margin-bottom: auto;
    }
    #url_input {
        min-width: 800px;
    }
    #url {
        min-width: 1000px;
        font-size: 30px;
        margin-top: 10px;
    }
    .congratsBox {
        /* width: 50%; */
    }
}

@media screen and (min-width: 1825px) {
    .calloutContainer {
        width: 90%;
    }
    
    .priceSpecList {
        position: absolute;
        right: -15%;
        top: 20%;
    }
    .mainInfo .grid .calloutContainer {
        grid-column-start: 2 span;
    }
}